import React, { useEffect, useLayoutEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { analyticClickEvent } from "../../../components/googleAnalytics"
import { ReactAudioPlayer, ReactVideoPlayer } from "../../../components/reactPlayer"
import Layout from "../../../components/layout"
import {
  Section,
  SectionHeading,
} from "../../../styledComponents/section"
import {
  StyledVideoTestimonialArticle,
  StyledVideoTestimonialHeader,
  StyledVideoTestimonialVideo,
} from "../../../styledComponents/videoTestimonial"
import { StyledButton } from "../../../styledComponents/button"
import { EventListSection } from "../../../styledComponents/eventList"
import ProductList from "../../../components/ProductList"
import { StyledLinkButton } from "../../../styledComponents/button"
import { Article, ArticleHeader, ArticleSection } from "../../../styledComponents/article"
import { StaticImage } from "gatsby-plugin-image"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const FhtjAacRecordings = ({ data, location }) => {

  let products = data.allWpProduct.edges

  const [tabIndex, setTabIndex] = useState(0);

  const checkIfHasQuery = () => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has("faq")) {
      setTabIndex(0)
    }
    if (urlParams.has("copperwire")) {
      setTabIndex(1)
    }
    if (urlParams.has("session-1")) {
      setTabIndex(2)
    }
    if (urlParams.has("session-2")) {
      setTabIndex(3)
    }
    if (urlParams.has("session-3")) {
      setTabIndex(4)
    }
    if (urlParams.has("session-4")) {
      setTabIndex(5)
    }
  }

  useEffect(() => {
    checkIfHasQuery()
  }, [])

  const [isWatchFullVersionOpen, setIsWatchFullVersionOpen] = useState(false);
  const [isAutopPlay, setAutoPlay] = useState(false);

  const toggleTestimonials = (value) => {
    setIsWatchFullVersionOpen(value)
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      const urlParams = new URLSearchParams(window.location.search)
      const autoplay = urlParams.get('autoplay')
      setAutoPlay(autoplay)
      return
    }
  }, [])

  return (
    <> <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
      <Layout displayNavBorder location={location} hideNewsletterSignUp>
        <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
          <StyledVideoTestimonialHeader>
            <h1 itemProp="headline">Abundance Access Codes</h1>
          </StyledVideoTestimonialHeader>
          <EventListSection style={{ "padding": "0" }}>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} >
              <TabList>
                <Tab>FAQ's</Tab>
                <Tab>Copper Wire</Tab>
                <Tab>Call 1</Tab>
                <Tab>Call 2</Tab>
                <Tab>Call 3</Tab>
                <Tab>Call 4</Tab>
              </TabList>
              <TabPanel>
                <h2>Faq's</h2>
                <ol className="faq">
                  <li>
                    <p>How often do we need to listen to the 17 audios plus 2 bonus audios that were shared</p>
                    <p>
                      Select 3-5 audios that are most relevant to your current situation. Listen to each of them once daily.
                      They can be listened to in one go, but there is no need to listen to them more than once a day. Keep
                      listening daily for about 5-6 weeks or until the issue is resolved. After 5-6 weeks, regardless of
                      whether you think the issue has been resolved, replace the list with different ones. This will allow new
                      energies to work on your abundance.
                    </p>
                  </li>
                  <li>
                    <p>
                      How many audios do we need to listen and allowed to listen per day (I can set almost 2 hours aside
                      a day)
                    </p>
                    <p>
                      It is recommended that a maximum of 5 audios be listened to daily. Although it is more beneficial to
                      relax and listen to them, they can be played in the background, as long as you are not operating
                      heavy machinery or driving.
                    </p>
                  </li>
                  <li>
                    <p>
                      Can we just listen to the audios while doing my office work or should I sit in meditation while
                      listening the audios
                    </p>
                    <p>
                      You can do either. It is recommended that listening to some in meditation will be useful, but it is not
                      necessary to listen to 50 minutes of audios or more whilst meditating! You can go about your daily life
                      whilst doing it. It is also not necessary to play them at a high volume. Any volume that is more than 0
                      on the dial will be fine, even if it is barely audible.
                    </p>
                  </li>
                  <li>
                    <p>How often do we need to repeat the audios</p>
                    <p>Listen to the audios once daily.</p>
                  </li>
                  <li>
                    <p>Do we need to repeat the live session recordings</p>
                    <p>
                      The live session recordings are very powerful. They usually cover a wider variety of topics. They can
                      be revisited every once in a while when you want to do a more thorough clearing, but definitely not
                      daily.</p> 
                      <p>You have the live sessions for life, so you might want to revisit some of your favourite sessions
                      once a month to keep the energies clearing. For example, session 1 of the live sessions focuses on a
                      set of clearings involving beliefs and ancestral effects. This would be useful to revisit every month or
                      even every other month.
                    </p>
                  </li>
                </ol>
              </TabPanel>
              <TabPanel>
                <h2>Copper wire</h2>
                <StaticImage
                  src="../../../images/copperwire.png"
                  alt="Copper wire diagram"
                  // width="100%"
                  // height="120px"
                  // layout="fullWidth"
                  style={{'marginTop':'10px'}}
                />
                <p style={{'color':'#c58000'}}>Copper wire lines two adjacent walls</p>
                <h3>
                  Room Layout
                </h3>
                <p>Place copper wire 16 inches long along two sides of the walls. Any two will do as long as the walls form a rough L shape.</p>
                <p>Sepearaste the wires by 2-3 inches. They must not touch each other.</p>
                <p>It does not matter if they are insulated or not.</p>
                <p>If you have a choice, thicher wire is better thank think wire, but it is not essential.</p>

                <h3>Further Notes:</h3>
                <p>All copper wire should be placed on the ground or close to the ground.</p>
                <p>They can be hidden inside or under carpets, cabinets, desks etc.</p>
                <p>There is no need to bend them into different shapes – straight copper wires will work fine, and they are easier to hide.
                </p>
              </TabPanel>
              <TabPanel>
                {/* Session 1 */}
                <h2 style={{ 'text-align': 'center' }}>Deep Cleaning of Ancestral, Belief, Energetic, Chakras and Aura Blockages Related to Abundance</h2>
                <StyledVideoTestimonialVideo className="replay">
                  {isWatchFullVersionOpen ? <ReactVideoPlayer
                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-aac/AAC-Zoom-Session-1.mp4"
                    title="AAC Session 1"
                    playing={true}
                    download={true}
                  />
                    :
                    <ReactAudioPlayer
                      autoPlay={false}
                      audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-aac/AAC-Zoom-Session-1.mp3"
                      onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: AAC Session 1')}
                      title="AAC Session 1"
                    />
                  }

                  {!isWatchFullVersionOpen ?
                    <>
                      <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-aac/AAC-Zoom-Session-1.mp3" download onClick={() => analyticClickEvent('click', "Download", 'AAC Session 1')}>
                        <svg width="27" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M0 8h2.2v11H0zM12 0h2.2v14H12z" /><path d="m5.987 8.242 1.555-1.555 7.071 7.07-1.555 1.556z" /><path d="m18.558 6.687 1.555 1.555-7.07 7.071-1.556-1.555zM24 8h2.2v11H24zM0 19h26.2v2.2H0z" /></g></svg>
                        Download audio</StyledLinkButton>
                      <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(true)}>Watch the replay</StyledButton>
                    </>
                    :
                    <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(false)}>Listen to the replay</StyledButton>
                  }
                </StyledVideoTestimonialVideo>
              </TabPanel>
              <TabPanel>
                {/* Session 2 */}
                <h2 style={{ 'text-align': 'center' }}>Building Earth Abundance Grid Immunity and Setting Up Powerful Inner Feng Shui Formations for Financial Strength</h2>
                <StyledVideoTestimonialVideo className="replay">
                  {isWatchFullVersionOpen ? <ReactVideoPlayer
                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-aac/AAC-Zoom-Session-2.mp4"
                    title="AAC Session 2"
                    onPlay={e => analyticClickEvent('Click', 'Watch', 'Play: AAC Session 2')}
                    playing={true}
                    download={true}
                  />
                    :
                    <>
                      <ReactAudioPlayer
                        autoPlay={false}
                        audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-aac/AAC-Zoom-Session-2.mp3"
                        onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: AAC Session 2')}
                        title="AAC Session 2"
                      />

                    </>
                  }

                  {!isWatchFullVersionOpen ?
                    <>
                      <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-aac/AAC-Zoom-Session-2.mp3" download onClick={() => analyticClickEvent('click', "Download", 'AAC Session 2')}>
                        <svg width="27" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M0 8h2.2v11H0zM12 0h2.2v14H12z" /><path d="m5.987 8.242 1.555-1.555 7.071 7.07-1.555 1.556z" /><path d="m18.558 6.687 1.555 1.555-7.07 7.071-1.556-1.555zM24 8h2.2v11H24zM0 19h26.2v2.2H0z" /></g></svg>
                        Download audio</StyledLinkButton>
                      <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(true)}>Watch the replay</StyledButton>
                    </>
                    :
                    <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(false)}>Listen to the replay</StyledButton>
                  }
                </StyledVideoTestimonialVideo>
              </TabPanel>

              <TabPanel>
                {/* Session 3 */}
                <h2 style={{ 'text-align': 'center' }}>Setting Up Inner Earth Grid Positions for Power, Career and Health</h2>
                <StyledVideoTestimonialVideo className="replay">
                  {isWatchFullVersionOpen ? <ReactVideoPlayer
                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-aac/AAC-Zoom-Session-3.mp4"
                    title="AAC Session 3"
                    onPlay={e => analyticClickEvent('Click', 'Watch', 'Play: AAC Session 3')}
                    playing={true}
                    download={true}
                  />
                    :
                    <>
                      <ReactAudioPlayer
                        autoPlay={false}
                        audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-aac/AAC-Zoom-Session-3.mp3"
                        onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: AAC Session 3')}
                        title="AAC Session 3"
                      />

                    </>
                  }

                  {!isWatchFullVersionOpen ?
                    <>
                      <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-aac/AAC-Zoom-Session-3.mp3" download onClick={() => analyticClickEvent('click', "Download", 'AAC Session 3')}>
                        <svg width="27" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M0 8h2.2v11H0zM12 0h2.2v14H12z" /><path d="m5.987 8.242 1.555-1.555 7.071 7.07-1.555 1.556z" /><path d="m18.558 6.687 1.555 1.555-7.07 7.071-1.556-1.555zM24 8h2.2v11H24zM0 19h26.2v2.2H0z" /></g></svg>
                        Download audio</StyledLinkButton>
                      <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(true)}>Watch the replay</StyledButton>
                    </>
                    :
                    <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(false)}>Listen to the replay</StyledButton>
                  }


                </StyledVideoTestimonialVideo>
              </TabPanel>

              <TabPanel>
                {/* Session 4 */}
                <h2 style={{ 'text-align': 'center' }}>Setting Up the Four Earth Abundance Grid Guardian Energies for Protection &amp; Growth</h2>
                <StyledVideoTestimonialVideo className="replay">

                  {isWatchFullVersionOpen ? <ReactVideoPlayer
                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-aac/AAC-Zoom-Session-4.mp4"
                    title="AAC Session 4"
                    onPlay={e => analyticClickEvent('Click', 'Watch', 'Play: AAC Session 4')}
                    playing={true}
                    download={true}
                  />
                    :
                    <>
                      <ReactAudioPlayer
                        autoPlay={false}
                        audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-aac/AAC-Zoom-Session-4.mp3"
                        onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: AAC Session 4')}
                        title="AAC Session 4"
                      />

                    </>
                  }

                  {!isWatchFullVersionOpen ?
                    <>
                      <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-aac/AAC-Zoom-Session-4.mp3" download onClick={() => analyticClickEvent('click', "Download", 'AAC Session 4')}>
                        <svg width="27" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M0 8h2.2v11H0zM12 0h2.2v14H12z" /><path d="m5.987 8.242 1.555-1.555 7.071 7.07-1.555 1.556z" /><path d="m18.558 6.687 1.555 1.555-7.07 7.071-1.556-1.555zM24 8h2.2v11H24zM0 19h26.2v2.2H0z" /></g></svg>
                        Download audio</StyledLinkButton>
                      <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(true)}>Watch the replay</StyledButton>
                    </>
                    :
                    <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(false)}>Listen to the replay</StyledButton>
                  }


                </StyledVideoTestimonialVideo>
              </TabPanel>

            </Tabs>
          </EventListSection>
        </StyledVideoTestimonialArticle>

        {/* <Article itemScope itemType="http://schema.org/Article">
          <ArticleHeader align>
            <h1 itemProp="headline">FAQs</h1>
          </ArticleHeader>
          <ArticleSection>
            <ol>
              <li>
                <p>How often do we need to listen to the 17 audios plus 2 bonus audios that were shared</p>
                <p>
                  Select 3-5 audios that are most relevant to your current situation. Listen to each of them once daily.
                  They can be listened to in one go, but there is no need to listen to them more than once a day. Keep
                  listening daily for about 5-6 weeks or until the issue is resolved. After 5-6 weeks, regardless of
                  whether you think the issue has been resolved, replace the list with different ones. This will allow new
                  energies to work on your abundance.
                </p>
              </li>
              <li>
                <p>
                  How many audios do we need to listen and allowed to listen per day (I can set almost 2 hours aside
                  a day)
                </p>
                <p>
                  It is recommended that a maximum of 5 audios be listened to daily. Although it is more beneficial to
                  relax and listen to them, they can be played in the background, as long as you are not operating
                  heavy machinery or driving.
                </p>
              </li>
              <p>
                Can we just listen to the audios while doing my office work or should I sit in meditation while
                listening the audios
              </p>
              <p>
                You can do either. It is recommended that listening to some in meditation will be useful, but it is not
                necessary to listen to 50 minutes of audios or more whilst meditating! You can go about your daily life
                whilst doing it. It is also not necessary to play them at a high volume. Any volume that is more than 0
                on the dial will be fine, even if it is barely audible.
              </p>
              <li>
                <p>How often do we need to repeat the audios</p>
                <p>Listen to the audios once daily.</p>
              </li>
              <li>
                <p>Do we need to repeat the live session recordings</p>
                <p>
                  The live session recordings are very powerful. They usually cover a wider variety of topics. They can
                  be revisited every once in a while when you want to do a more thorough clearing, but definitely not
                  daily. You have the live sessions for life, so you might want to revisit some of your favourite sessions
                  once a month to keep the energies clearing. For example, session 1 of the live sessions focuses on a
                  set of clearings involving beliefs and ancestral effects. This would be useful to revisit every month or
                  even every other month.
                </p>
              </li>
            </ol>
          </ArticleSection>
        </Article> */}


        <Section>
          <Heading level={1} className="hidden"> Audio Programs &amp; Meditations</Heading>
          <ProductList posts={products} />
        </Section>

      </Layout>
    </>
  )
}

export default FhtjAacRecordings

export const pageQuery = graphql`
      query {
        site {
        siteMetadata {
        title
      }
    }

      wpPage(slug: {eq: "homepage" }) {
        title
      slug
      content
      testimonials {
        blockquote {
        citeLocation
          citationName
      quote
        }
      testimonialHeading
      testimonialLede
      }
    }

      aboutKarina: wpPage(slug: {eq: "about" }) {
        aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
        localFile {
        childImageSharp {
        gatsbyImageData
      }
          }
        }
      }
    }
      

      allWpPost(sort: {fields: [date], order: DESC } limit: 5) {
        nodes {
        excerpt
        uri
      date(formatString: "MMMM DD, YYYY")
      title
      isSticky
      categories {
        nodes {
        slug
      }
        }
      featuredImage {
        node {
        altText
            localFile {
        childImageSharp {
        gatsbyImageData(
          width: 975
      quality: 100
      placeholder: TRACED_SVG
      breakpoints: [320, 720, 1024, 1600]
      )
              }
            }
          }
        }
      }
    }

    allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {title: {ne: "Permission Bonus"}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    #   allWpProduct(limit: 3) {
    #     edges {
    #     node {
    #     title
    #       uri
    #   excerpt
    #   productsTag {
    #     nodes {
    #     name
    #   }
    #       }
    #   productData {
    #     pricefull
    #         pricesale
    #   stripefullprice
    #   stripesaleprice
    #   hidesale
    #       }
    #   featuredImage {
    #     node {
    #     localFile {
    #     childImageSharp {
    #     gatsbyImageData
    #   }
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
}
      `
